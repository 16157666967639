import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-material-stock-detail-modal',
  templateUrl: './material-stock-detail-modal.page.html',
  styleUrls: ['./material-stock-detail-modal.page.scss'],
})
export class MaterialStockDetailModalPage implements OnInit {

  @Input() data: any;

  constructor(public modalController: ModalController) { }

  ngOnInit() {
    console.log(this.data);

  }
  dismiss() {
    this.modalController.dismiss();
  }
}
