/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { API_URL, CREATE_NODE_URL } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  public VENDOR_LIST_URL = API_URL + '/mobileapp/list/vendors?_format=json';
  public WAREHOUSE_LIST_URL = API_URL + '/mobileapp/list/warehouse?_format=json';
  public STOCK_MATERIALS_LIST_URL = API_URL + '/mobileapp/list/stock/material?_format=json';
  public STOCK_MATERIALS_TRANSACTION_URL = API_URL + '/mobileapp/stock/material/transactions?_format=json';
  public STOCK_SUMMARY_URL = API_URL + '/mobileapp/stock/summary?_format=json';

  constructor(
    public userService: UserService,
    public toastController: ToastController,
    private httpClient: HttpClient,
    public alertController: AlertController) { }


  get_vendor_list(): Observable<any[]> {
    if (!this.userService.btoa) {
      this.userService.initiate();
    }
    const headers_object = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.userService.btoa);
    this.userService.setUserInfo();
    const profile = this.userService.getUserInfo();
    const httpOptions = {
      headers: headers_object
    };
    return this.httpClient.get<any>(this.VENDOR_LIST_URL, httpOptions);
  }

  get_warehouse_list(): Observable<any[]> {
    if (!this.userService.btoa) {
      this.userService.initiate();
    }
    const headers_object = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.userService.btoa);
    this.userService.setUserInfo();
    const profile = this.userService.getUserInfo();
    const httpOptions = {
      headers: headers_object
    };
    return this.httpClient.get<any>(this.WAREHOUSE_LIST_URL, httpOptions);
  }

  get_stockmaterial_list(): Observable<any[]> {
    if (!this.userService.btoa) {
      this.userService.initiate();
    }
    const headers_object = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.userService.btoa);
    this.userService.setUserInfo();
    const profile = this.userService.getUserInfo();
    const httpOptions = {
      headers: headers_object
    };
    return this.httpClient.get<any>(this.STOCK_MATERIALS_LIST_URL, httpOptions);
  }

  submit_stock_materials_request(postData): Observable<any[]> {
    if (!this.userService.btoa) {
      this.userService.initiate();
    }
    const headers_object = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.userService.btoa);
    this.userService.setUserInfo();
    const profile = this.userService.getUserInfo();
    const httpOptions = {
      headers: headers_object
    };
    return this.httpClient.post<any>(CREATE_NODE_URL, postData, httpOptions);
  }

  getMovementHistoryHistory(page = 0): Observable<any[]> {
    if (!this.userService.btoa) {
      this.userService.initiate();
    }
    const headers_object = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.userService.btoa);
    this.userService.setUserInfo();
    const profile = this.userService.getUserInfo();
    const httpOptions = {
      headers: headers_object
    };
    return this.httpClient.get<any>(this.STOCK_MATERIALS_TRANSACTION_URL + '&page=' + page, httpOptions);

  }

  getStockSummary(param): Observable<any[]> {
    if (!this.userService.btoa) {
      this.userService.initiate();
    }
    const headers_object = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic ' + this.userService.btoa);
    this.userService.setUserInfo();
    const profile = this.userService.getUserInfo();
    const httpOptions = {
      headers: headers_object
    };
    return this.httpClient.get<any>(this.STOCK_SUMMARY_URL + param, httpOptions);

  }
}
