import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-expense-detail-modal',
  templateUrl: './expense-detail-modal.page.html',
  styleUrls: ['./expense-detail-modal.page.scss'],
})
export class ExpenseDetailModalPage implements OnInit {

  @Input() data: any;
  @Input() selectedDate: any;

  constructor(public modalController: ModalController) { }

  ngOnInit() {
  }
  dismiss() {
    this.modalController.dismiss();
  }
}
